<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      :persistent="true"
      ref="dialog"
      max-width="464px"
      content-class="new-raido-popup"
      scrollable
    >
    <v-card>
      <v-card-title>
        <span>{{ titlePopup || title }}</span>
      </v-card-title>
      <v-card-text id="radio-group">
        <v-radio-group v-model="radioGroup" hide-details>
          <v-list-item tabindex="1" v-for="item in items" :key="item.id" @keyup.13="selectMethod(item)" @keyup.32="selectMethod(item)" :class="{ 'show-dot': isAtiveMethods(item.value, item.text), 'disabled-radio': item.id === 3 && scope === 3 && category === 4 && disabled }">
            <v-list-item-action>
              <v-radio :ripple="false" :label="item.name" :value="item.id" color="success" class="">
                <template v-slot:label>
                  <div class="d-flex flex-column">
                    <div class="sub-title">{{ item.name }}</div>
                    <div v-if="showDescription" class="sub-description">{{ item.description }}</div>
                  </div>
                </template>
              </v-radio>
            </v-list-item-action>
          </v-list-item>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <common-button tabindex="2" @action="close" :label="btnCancel"></common-button>
        <common-button tabindex="3" @action="submit" :label="btnDecision" type="colored"></common-button>
      </v-card-actions>
    </v-card>
      
    </v-dialog>
  </div>
</template>
<script>
import CommonButton from '@/components/utils/button.vue';
import { mapState } from 'vuex'
export default {
  components: { CommonButton },
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    radioType: {
      type: String,
      default: 'Scopes',
    },
    titlePopup: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    method: {
      type: Number,
      default: null,
    },
    currentSelect: {
      type: Number,
      default: null,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    listMethodsAtive: {
      type: Array,
      default:()=>[]
    },
    methodListLayer2: {
      type: Array,
      default:()=>[]
    },
    scope: {
      type: Number,
      default: null,
    },
    category: {
      type: Number,
      default: null,
    },
    isLayer2: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    radioGroup: {
      get() {
        return this.currentSelect;
      },
      set(val) {
        this.$emit('update:currentSelect', val);
      },
    },
    title: {
      get() {
        if (this.radioType == 'Scopes') {
          return this.$t('register_data.popup_select_method');
        }
        return this.$t('register_data.popup_select_method');
      },
    },
    ...mapState("userData", ["isSettingPcaf"]),
    btnDecision () {
      return this.$t('customized_db_management.button_decision')
    },
    btnCancel () {
      return this.$t('customized_db_management.button_cancel')
    }
  },
  watch: {
    dialog(val) {
      if(val && !this.radioGroup) {
        this.$nextTick(_=> {
          document.getElementById("radio-group").scrollTop = 0
        })
      }
    }
  },

  methods: {
    isAtiveMethods(methods, text){
      if(this.scope === 3 && this.category === 4){
        if (!this.isLayer2) {
          // handle data for layer 1
          const methods1 = this.listMethodsAtive.filter(item => [4,5,6].includes(item));
          const methods2 = this.listMethodsAtive.filter(item =>[7,8].includes(item));
          if(methods === 1 && methods1.length) return true;
          if(methods === 2 && methods2.length) return true;
          if(methods === 3 && this.listMethodsAtive.includes(2) ) return true;
        } else {
          // handle data for layer 2
          if (this.listMethodsAtive.includes(methods)) return true;
        }
      };
      if(this.scope === 3 && this.category === 9) {
        if (!this.isLayer2) {
          // handle data for layer 1
          const methods1 = this.listMethodsAtive.filter(item => [1, 2, 3].includes(item));
          const methods2 = this.listMethodsAtive.filter(item =>[4, 5].includes(item));
          if (methods === 1 && methods1.length) return true;
          if (methods === 2 && methods2.length) return true;
        } else {
          // handle data for layer 2
          if (this.listMethodsAtive.includes(methods)) return true;
        }
      };
      if(this.scope === 3 && this.category === 11) {
        // handle data for layer 2
        if(this.listMethodsAtive.includes(methods) && this.isLayer2) return true;

        // handle data for layer 1
        if (!this.isLayer2) {
          if(methods === 2 && this.listMethodsAtive.includes(4)) return true;
          if (methods === 1 && this.listMethodsAtive.some(item => [1, 2, 3].includes(item))) return true;
        }
      };

      if(this.methodListLayer2.length === 0 && (!([11,9,4].includes(this.category)) || this.category === 15)){
        return this.listMethodsAtive.includes(methods)
      }
    },
    selectMethod(method){
      this.radioGroup = method.value
    },
    close() {
      this.$emit('close');
      this.radioGroup = this.method;
    },
    handleClickOutSite() {
      this.$emit('close');
      this.radioGroup = this.method;
    },
    submit() {
      this.$emit('onChange', this.radioGroup);
      this.$emit('submit');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>
